<template>
  <div id="cps">
    <CreditCard v-if="isCC" />
    <CPS v-else />
  </div>
</template>

<script>
import CPS from '@/components/deposit/Cps/Index.vue';
import CreditCard from '@/components/deposit/Cps/CreditCard.vue';
import { creditCardData } from '@/constants/payment/depositSetting.json';

export default {
  name: 'cpsView',
  components: { CPS, CreditCard },
  computed: {
    paymentMethod() {
      return this.$store.state.cps.payment.payment_method;
    },
    isCC() {
      return creditCardData.includes(this.paymentMethod);
    }
  }
};
</script>
